.parentContainer {
  width: 100%;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -200px;
}
.allInOneContainer {
  position: relative;
  padding: 71px 0;
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.firstSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondSection {
  max-width: 520px;
}
.title {
  color: var(--neutrals-white, #fff);
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: -0.025rem;
  text-align: center;
}
.imageContainer {
  display: flex;
  gap: 12px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.rankImg {
  width: 249px;
  height: 59px;
}

.subTitle {
  margin-top: 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0125rem;
  margin-bottom: 15px;
}

@media screen and (max-width: 800px) {
  .parentContainer {
    background-position: 0;
  }
  .allInOneContainer {
    max-width: 90%;
    margin: auto;
    flex-direction: column;
    align-items: flex-start;
  }
  .firstSection {
    width: 100%;
    align-items: center;
  }
  .secondSection {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    align-items: center;
  }
  .imageContainer {
    justify-content: center;
  }
}
